<template>
    <!-- 菜单权限管理 -->
    <div class="paddbox autobox">
      <div>
        <el-card>
            <div class="flex">
              <div class="titleName">新增备款账户:</div>
              <div><el-input placeholder="请输入备款的账户id" v-model="addAcountId" clearable/>
              </div>
              <div><el-button type="primary" @click="addTransfeAcountFun()" >添加</el-button></div>
            </div>

          <el-divider></el-divider>
          <!-- table部分 -->
          <div>  
            <el-table
    :data="table"
    border
    style="width: 90%">
    <el-table-column
      prop="accountId"
      label="备款账户id"
      width="180">
    </el-table-column>
    <el-table-column
      prop="adBalance"
      label="广告充值金"
      width="130">
    </el-table-column>
    <el-table-column
      prop="duanjuBalance"
      label="短剧内购赠送金资金账户"
      width="180">
    </el-table-column>
    <el-table-column
      prop="jingJiaAdBalance"
      label="竞价信用账户"
      width="130">
    </el-table-column>
    <el-table-column
      prop="principalName"
      label="主体名称"
      width="230">
    </el-table-column>
    <el-table-column
      prop="serviceProviderName"
      label="服务商名称"
      width="230">
    </el-table-column>
    <el-table-column
      label="操作">
      <template #default="scope">
      <div>
        <el-button type="primary" size="mini"  @click="TransferFun(scope.row)">转出</el-button>
        <el-button type="success" size="mini" @click="refershBeiuanAccountIdFun(scope.row)">刷新</el-button>
        <el-button  size="mini" @click="updateFun(scope.row)">修改</el-button>
        <el-button type="danger" size="mini" @click="deleteFun(scope.row)">删除</el-button>
      </div>
    </template>
    </el-table-column>
  </el-table></div>

        </el-card>
      </div>
  
      <div>


      </div>
    </div>
        <!-- 转出弹窗 -->
        <el-dialog v-model="openTransferview" title="转账">
          <div>
            <el-form  label-width="240px" >
  <el-form-item label="备款账户id：">
    <el-input v-model="AccountData.accountId" disabled style="width: 200px;"></el-input>
  </el-form-item>
  <el-form-item label="可用广告充值金：">
    <el-input v-model="AccountData.adBalance" disabled style="width: 200px;"></el-input>
    <i>元</i>
  </el-form-item>
  <el-form-item label="可用短剧内购赠送金资金账户：">
    <el-input v-model="AccountData.duanjuBalance" disabled style="width: 200px;"></el-input>
    <i>元</i>
  </el-form-item>
  <el-form-item label="可用竞价信用资金账户：">
    <el-input v-model="AccountData.jingJiaAdBalance" disabled style="width: 200px;"></el-input>
    <i>元</i>
  </el-form-item>
  <el-form-item label="资金账户类型：">
    <el-radio-group v-model="fundType">
      <el-radio-button label="FUND_TYPE_CASH">广告充值金</el-radio-button>
      <el-radio-button label="FUND_TYPE_CREDIT_ROLL" >竞价信用账户</el-radio-button>
      <el-radio-button label="FUND_TYPE_SHORT_PLAY_GIFT" disabled>短剧内购赠送金资金账户</el-radio-button>
    </el-radio-group>
  </el-form-item>
  <el-form-item >
    <i style="color: #FF0000;">只能同主体之间转账</i>
  </el-form-item>
  <el-form-item label="转账金额：">
    <el-input v-model="amount" style="width: 200px;" clearable></el-input>
    <i>元</i>
  </el-form-item>
  <el-form-item label="转账备注：">
    <el-input v-model="memo" style="width: 600px;" clearable></el-input>
  </el-form-item>
  <el-form-item>
    <div>
        <el-button type="success"  @click="upAccountFun()">添加转账账户</el-button>
        <el-button type="danger"   @click="delAccountFun()">清空账户</el-button>
    </div>
  </el-form-item>
  <el-form-item label="需要转账的账户：">
    <el-tag
  v-for="tag in accountIds"
  :key="tag"
  type="success"
  >
  {{tag}}
</el-tag>
  </el-form-item>
  <el-form-item >
    <el-button type="primary"  @click="transferAccountIdFun()">确定</el-button>
    <el-button type="primary"   @click="openTransferFun()">取消</el-button>
  </el-form-item>
</el-form> 
          </div>
        </el-dialog>
  <!-- 修改弹窗 -->
   <el-dialog v-model="updateview" title="修改信息">
      <div>
    <el-form  label-width="240px" >
      <el-form-item label="备款账号id">
      <el-input v-model="AccountData.accountId" disabled style="width: 300px;" clearable></el-input>
    </el-form-item>
     <el-form-item label="主体名称：">
      <el-input v-model="AccountData.principalName" style="width: 300px;" clearable></el-input>
    </el-form-item>
    <el-form-item label="服务商名称：">
      <el-input v-model="AccountData.serviceProviderName" style="width: 300px;" clearable></el-input>
    </el-form-item>
    <el-form-item >
      <el-button type="primary"  @click="updateBeiuanAccountFun()">确定</el-button>
      <el-button type="primary"   @click="updateviewFun()">取消</el-button>
    </el-form-item>
      </el-form> 
        </div>
   </el-dialog>
     <!-- 删除弹窗 -->
     <el-dialog v-model="deleteview" title="删除账号">
      <div>
    <el-form  label-width="240px" >
      <el-form-item label="备款账号id">
      <el-input v-model="AccountData.accountId" disabled style="width: 300px;" clearable></el-input>
    </el-form-item>
     <el-form-item label="主体名称：">
      <el-input v-model="AccountData.principalName" disabled style="width: 300px;" clearable></el-input>
    </el-form-item>
    <el-form-item label="服务商名称：">
      <el-input v-model="AccountData.serviceProviderName" disabled style="width: 300px;" clearable></el-input>
    </el-form-item>
    <el-form-item >
      <el-button type="primary"  @click="deleteBeiuanAccountFun()">确定</el-button>
      <el-button type="primary"   @click="deleteviewFun()">取消</el-button>
    </el-form-item>
      </el-form> 
        </div>
   </el-dialog>
        <!-- 选择子账户弹窗 -->
        <el-dialog v-model="openSonview">
      <insertViewMP
        @getAccountListFun="getSonAccountListFun"
        @getCannelFun="getCannelFun"
      ></insertViewMP>
    </el-dialog>
  </template>
  
  <script>
  import { 
  MPselectAccountIdIsNotExsit,
  selectBeiuanAccountId,
  refershBeiuanAccountId,
  addBeikuanAccountId,
  transferAccountId,
  deleteBeiuanAccount,
  updateBeiuanAccount
  } from '@/request/new/videoBatchManagement'
  import { ElMessage } from 'element-plus'
  import { inject, reactive, toRefs, watch } from 'vue'
  import insertViewMP from '@/components/batch/insertViewMP.vue'
  
  export default {
    name: 'mpTransfer',

    components: {
    insertViewMP,
  },

    setup() {
      const state = reactive({
      // 选中的备款账户信息
      AccountData:{
        id:'',
        accountId:'',
        adBalance:'',
        duanjuBalance:'',
        jingJiaAdBalance:'',
        //主体名称
        principalName:'',
        //服务商名称
        serviceProviderName:'',
      },
      //转账的金额
      amount:'',
      //备注
      memo:'',
      //选中的子账户
      accountIds:[],
      //  资金账户类型 FUND_TYPE_CASH广告充值金  FUND_TYPE_SHORT_PLAY_GIFT短剧内购赠送金资金账户
      fundType:'FUND_TYPE_CASH',
      // 打开转出弹窗
      openTransferview: false,
      // 打开修改弹窗
      updateview: false,
      // 打开删除弹窗
      deleteview: false,
      // 打开选择子账户的弹窗
      openSonview: false,
      // 选择子账户的按钮是否可用
      openSonviewAble: false,
        //转出表格数据
        table:'',
        //新增的转出账户id
        addAcountId:'',
        //新增转出账户
        addTransfeAcountFun:()=>{
          //账号不能为空
          if(state.addAcountId==''){
            return   ElMessage({
              type: 'error',
              message: '备款账户不能为空',
            })
          }
          //效验账户是否存在
          const datas = {
            advertiserId: state.addAcountId,
          }
          MPselectAccountIdIsNotExsit(datas).then((res) => {
            //存在
          if (res.code == 200) {
            state.addBeikuanAccountIdFun()
          }
        })
        },
        //添加转出账户
        addBeikuanAccountIdFun:()=>{
          const datas = {
            accountId: state.addAcountId,
          }
          addBeikuanAccountId(datas).then((res)=>{
            state.addAcountId=''
             ElMessage({
              type: 'success',
              message: '添加成功',
            })
            selectBeiuanAccountIdFun()

          })
        },
        //点击修改
        updateFun:(param)=>{
          state.assignTo(param)
          state.updateviewFun()
        },
        //点击删除
        deleteFun:(param)=>{
          state.assignTo(param)
          state.deleteviewFun()
        },

        //刷新转出账户
        refershBeiuanAccountIdFun:(data)=>{
          const datas={
            accountId:data.accountId
          }
          refershBeiuanAccountId(datas).then((res) => {
            selectBeiuanAccountIdFun()
            ElMessage({
              type: 'success',
              message: '刷新成功',
            })
        })
        }, 
      // 接收组件的取消
      getCannelFun: () => {
        state.upAccountFun()
      },
      // 修改打开选择子账户的弹窗
      upAccountFun: () => {
        state.openSonview = !state.openSonview
      },
      // 修改打开选择子账户的弹窗
      openTransferFun: () => {
        state.openTransferview = !state.openTransferview
      },
      // 修改打开选择子账户的弹窗
      updateviewFun: () => {
        state.updateview = !state.updateview
      },
      // 修改打开选择子账户的弹窗
      deleteviewFun: () => {
        state.deleteview = !state.deleteview
      },
      //重置账户
      delAccountFun:()=>{
        state.accountIds=[]
      },
      //赋值数据
      assignTo:(param)=>{
        state.AccountData.id=param.id
        state.AccountData.accountId=param.accountId
        state.AccountData.adBalance=param.adBalance
        state.AccountData.duanjuBalance=param.duanjuBalance
        state.AccountData.jingJiaAdBalance=param.jingJiaAdBalance
        state.AccountData.principalName=param.principalName
        state.AccountData.serviceProviderName=param.serviceProviderName
      },
      //修改里面的确定
      updateBeiuanAccountFun:()=>{
        const datas ={
          id:state.AccountData.id,
          principalName:state.AccountData.principalName,
          serviceProviderName:state.AccountData.serviceProviderName
        }
        updateBeiuanAccount(datas).then((res)=>{
          ElMessage({
              type: 'success',
              message: '修改成功',
            })
        selectBeiuanAccountIdFun()
        state.updateviewFun()
          
        })

      },
      //删除里面的确定
      deleteBeiuanAccountFun:()=>{
        const datas ={
          id:state.AccountData.id,
        }
        deleteBeiuanAccount(datas).then((res)=>{
          ElMessage({
              type: 'success',
              message: '删除成功',
            })
        selectBeiuanAccountIdFun()
        state.deleteviewFun()
          
        })
      },
      //点击转出
      TransferFun:(param)=>{
        state.assignTo(param)
        state.openTransferFun()
      },
      // 接收选择子账户组件的确定
      getSonAccountListFun: (data) => {
        state.accountIds = data
        state.upAccountFun()
      },
      //转账确定
      transferAccountIdFun:()=>{
        if(state.amount==''){
        return   ElMessage({
              type: 'error',
              message: '转账金额不能为空！！！',
            })
        }
        if(state.accountIds.length==0){
        return   ElMessage({
              type: 'error',
              message: '需要转账的账户不能为空！！！',
            })
        }
        const datas={
          accountId:state.AccountData.accountId,
          amount:state.amount,
          toAccountIds:state.accountIds,
          fundType:state.fundType,
          memo:state.memo
        }
        transferAccountId(datas).then((res)=>{
          state.amount=''
          state.memo=''
          state.openTransferFun()
          state.refershBeiuanAccountIdFun(state.AccountData)
        })


      },

      })
      //查询转出账号
      const selectBeiuanAccountIdFun = () => {

        selectBeiuanAccountId().then((res) => {
          state.table=res.data
        })
      }
  
      selectBeiuanAccountIdFun()
      return {
        // 结构赋值，暴露出去就不用 state.xx
        ...toRefs(state),
        
      }
    },
  }
  </script>
  
  <style lang="scss" scoped>
  .titleName{
    padding-top: 10px;
    font-size: 16px;
  }
  </style>
  